import { z } from "zod";

import { isEmailRegex } from "../../../../../../util/regex";
import { ValidationFunction } from "../../lookup";

export const emailValidation: ValidationFunction = (value) => {
  const result = z.string().regex(isEmailRegex).safeParse(value);

  if (!result.success) {
    return {
      ok: false,
      message: { tx: "auth.signUp.inputs.email.notAnEmailError" },
    };
  }

  return {
    ok: true,
  };
};
