import { z } from "zod";

import { AuthPagesEnum } from "../auth/constants";

const schemaAuthPagesEnum = z.enum(
  Object.values(AuthPagesEnum) as [string, ...string[]],
);

export const isAuthRoute = (
  maybeAuthRoute: unknown,
): maybeAuthRoute is AuthPagesEnum =>
  schemaAuthPagesEnum.safeParse(maybeAuthRoute).success;
