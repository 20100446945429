import { z } from "zod";

const errorSchema = z.object({
  name: z.string(),
});

export const isErrorWithName = (error: unknown, name: string): boolean => {
  const result = errorSchema.safeParse(error);
  if (!result.success) {
    return false;
  }

  return result.data.name === name;
};
