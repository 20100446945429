import React from "react";

import { cn } from "~/util/cn";

type LoadingProps = {
  type?: "spinner" | "dots" | "ring" | "ball" | "bars" | "infinity";
  size?: "sm" | "xs" | "md" | "lg";
  loading: boolean;
  otherwise?: JSX.Element | null;
};

const loadingTypes = {
  spinner: "loading-spinner",
  dots: "loading-dots",
  ring: "loading-ring",
  ball: "loading-ball",
  bars: "loading-bars",
  infinity: "loading-infinity",
};

const loadingSizes = {
  sm: "loading-sm",
  xs: "loading-xs",
  md: "loading-md",
  lg: "loading-lg",
};

export const Loading: React.FC<LoadingProps> = ({
  type = "ring",
  size = "md",
  loading,
  otherwise = null,
}) => {
  const loadingClass = `${loadingTypes[type]} ${loadingSizes[size]}`;

  return loading ? <span className={cn("loading", loadingClass)} /> : otherwise;
};
