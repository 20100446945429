import * as Sentry from "@sentry/react";
import { t } from "i18next";
import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { PlainI18nProps } from "shared/types/i18n";
import { TranslationFeKey } from "shared/types/translation-key";
import { z } from "zod";

export class MamaCognitoError extends Error {
  public constructor({ name }: { name: TranslationFeKey }) {
    super(t(name));
    this.name = name;
    Object.setPrototypeOf(this, MamaCognitoError.prototype);
  }
}

export interface CognitoError {
  name: string;
  message: string;
}

export const handleCognitoError = (
  error: unknown,
  user: Sentry.User,
): PlainI18nProps => {
  if (isCognitoError(error)) {
    Sentry.captureException(new Error(error.message), {
      user,
      level: "error",
    });
    return returnLocalizedCognitoError(error.name, error.message);
  }

  Sentry.captureException(new Error("An unknown error has occured"), {
    user,
    level: "error",
  });
  return { txUnchecked: "auth.signIn.errors.UnknownError" };
};

const schemaCognitoError = z.object({
  name: z.string(),
  message: z.string(),
});

export const isCognitoError = (error: unknown): error is CognitoError =>
  schemaCognitoError.safeParse(error).success;

const returnLocalizedCognitoError = (
  errorName: string,
  errorMessage: string,
): PlainI18nProps => {
  if (errorName === "UserLambdaValidationException") {
    const split = errorMessage.split(" failed with error ");

    return split.length === 2
      ? {
          txUnchecked: `auth.signIn.errors.${split[1]}`,
          text: errorMessage,
        }
      : { text: errorMessage };
  }

  return {
    txUnchecked: `auth.signIn.errors.${errorName}`,
    txData: { min_password_length: MIN_PASSWORD_LENGTH },
    text: errorMessage,
  };
};
