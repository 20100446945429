import * as Sentry from "@sentry/react";
import { Paperclip, Upload, X } from "lucide-react";
import { useRef } from "react";
import { MAX_AMOUNT_ATTACHMENTS } from "shared/utils/constants";

import { useToasts } from "../app-shell/providers/toasts-provider/toasts-provider";
import { Text } from "../text";
import { useT } from "~/i18n/use-t";
import { cn } from "~/util/cn";
import { useWindowDimensions } from "~/util/hooks";

export type Attachment = {
  id: string;
  file: File;
  preview: string;
  base64: string;
  name: string;
  size: number;
};

export const AttachmentManager: React.FC<{
  attachments: Attachment[];
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
}> = ({ attachments, setAttachments }) => {
  const removeAttachment = (id: string): void => {
    setAttachments((prev) => {
      const filtered = prev.filter((attachment) => attachment.id !== id);
      const removedFile = prev.find((attachment) => attachment.id === id);
      if (removedFile) {
        URL.revokeObjectURL(removedFile.preview);
      }
      return filtered;
    });
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return bytes + " B";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
    else return (bytes / 1048576).toFixed(1) + " MB";
  };

  return (
    <div className="mb-4 flex flex-wrap gap-5">
      {attachments.map((attachment) => (
        <div
          key={attachment.id}
          className="relative rounded-lg border border-neutral/20 bg-base-100 p-1 shadow-sm"
        >
          {attachment.file.type.startsWith("image/") ? (
            <div
              className="tooltip tooltip-top"
              data-tip={`${attachment.name} (${formatFileSize(
                attachment.size,
              )})`}
            >
              <img
                src={attachment.preview}
                alt={attachment.name}
                className="h-16 w-16 rounded object-cover"
              />
            </div>
          ) : (
            <div className="flex h-16 w-16 items-center justify-center rounded bg-base-300">
              <span className="text-xs">
                {attachment.name.split(".").pop()?.toUpperCase() || ""}
              </span>
            </div>
          )}
          <button
            className="absolute -right-2 -top-2 rounded-full border border-neutral/10 bg-base-300 p-1 shadow-md hover:bg-base-200"
            onClick={() => removeAttachment(attachment.id)}
            type="button"
            aria-label="Remove attachment"
          >
            <X size={12} />
          </button>
        </div>
      ))}
    </div>
  );
};

const convertBlobToBase64 = (blob: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      const error = new Error("An unknown error has occured");
      Sentry.captureException(error, {
        level: "error",
      });
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
};

export const ButtonAttachments: React.FC<{
  attachments: Attachment[];
  setAttachments: (value: React.SetStateAction<Attachment[]>) => void;
  className?: string;
}> = ({ attachments, setAttachments, className }) => {
  const t = useT();
  const toasts = useToasts();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { width } = useWindowDimensions();

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;
    if (!files || files.length === 0) return;

    let list = Array.from(files);
    if (list.length > MAX_AMOUNT_ATTACHMENTS) {
      toasts.addToast({
        component: <Text tx="chat.errors.maximumFiveImages" />,
        variant: "error",
      });
      list = list.slice(0, MAX_AMOUNT_ATTACHMENTS);
    }

    let base64s: string[] = [];
    try {
      base64s = await Promise.all(
        list.map((file) => convertBlobToBase64(file)),
      );
    } catch (err) {
      toasts.addToast({
        component: (
          <Text
            tx="cognito.customFieldsError"
            txData={{ error: "could not upload attachments" }}
          />
        ),
      });
      return;
    }

    const newFiles: Attachment[] = list.map((file, index) => ({
      id: Math.random().toString(36).substring(2),
      file,
      preview: URL.createObjectURL(file),
      base64: base64s[index],
      name: file.name,
      size: file.size,
    }));

    setAttachments((prev) => [...prev, ...newFiles]);
    if (fileUploadRef.current) {
      fileUploadRef.current.value = "";
    }
  };

  const triggerFileInput = (e: React.MouseEvent) => {
    if (width < 640) {
      e.preventDefault();

      const id = toasts.addToast({
        autoClose: false,
        component: (
          <div className="flex flex-col items-center gap-3">
            <Text tx="chat.onlyJpgPngAllowed" />
            <button
              title="upload"
              type="button"
              className="btn-primary btn-block btn"
              onClick={() => {
                if (fileUploadRef.current) {
                  fileUploadRef.current.click();
                }
                toasts.removeToast(id);
              }}
            >
              <Upload className="mr-2" />
              <Text tx="socialMedia.uploadMedia" />
            </button>
          </div>
        ),
      });
    } else {
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    }
  };

  const isMaxAttachmentsReached = attachments.length >= MAX_AMOUNT_ATTACHMENTS;
  const tooltipText = isMaxAttachmentsReached
    ? t({ tx: "chat.errors.maximumFiveImages" })
    : t({ tx: "chat.onlyJpgPngAllowed" });

  return (
    <div
      className={cn("sm:tooltip sm:tooltip-right", className)}
      data-tip={tooltipText}
    >
      <button
        type="button"
        className={`btn-outline btn-square btn-sm btn border-neutral/20 shadow-md ${
          isMaxAttachmentsReached ? "btn-disabled opacity-50" : ""
        }`}
        onClick={triggerFileInput}
        disabled={isMaxAttachmentsReached}
        aria-label="Upload attachment"
      >
        <Paperclip width={16} />
      </button>
      <input
        title="upload"
        ref={fileUploadRef}
        id="file-upload"
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={handleFileUpload}
        className="hidden"
        multiple
        disabled={isMaxAttachmentsReached}
      />
    </div>
  );
};
