import React, { PropsWithChildren } from "react";

import { MAX_AMOUNT_OF_XP } from "./constants";
import { toTenantIdHeader, useApiQuery } from "../../../api/use-api";
import { CHAT_COMPLETENESS_KEY } from "../../../types/query-keys";
import { useTenantId } from "../../../util/use-active-tenant-id";
import { cn } from "~/util/cn";

export const ChatCompletion: React.FC<
  PropsWithChildren<{
    conversationId?: string;
  }>
> = ({ children, conversationId }) => {
  const tenantId = useTenantId();

  const { data: percentage } = useApiQuery(
    "multiagent",
    (api) =>
      api.getMultiagentChatCompleteness({
        ...toTenantIdHeader(tenantId),
        conversationId: conversationId as string,
      }),
    CHAT_COMPLETENESS_KEY(tenantId.disease),
    undefined,
    { enabled: !!conversationId },
  );

  return (
    <div className="mx-auto grid w-full max-w-screen-lg grid-cols-1 grid-rows-[min-content_min-content] gap-y-2 px-2 py-1.5 animate-slideIn sm:grid-cols-[1fr_640px_1fr] lg:grid-rows-1">
      <ChatCompletionLinearProgress
        percentage={percentage}
        className="col-span-1 row-start-1 flex px-2 sm:col-start-2 lg:hidden"
      />
      <ChatCompletionCircularProgress
        percentage={percentage}
        className="col-start-1 hidden lg:flex"
      />
      <div className="col-span-1 row-start-2 flex w-full flex-row justify-center gap-2 sm:col-start-2 lg:row-start-1">
        {children}
      </div>
    </div>
  );
};

const ChatCompletionLinearProgress: React.FC<{
  percentage?: number;
  className?: string;
}> = ({ percentage, className }) => {
  const value = percentage
    ? Math.floor(((percentage ?? 0) / 100) * MAX_AMOUNT_OF_XP)
    : 0;

  return (
    <div className={cn("flex items-center justify-between gap-4", className)}>
      <progress
        className="progress-primary progress w-full"
        value={value}
        max={MAX_AMOUNT_OF_XP}
      />
      <div className="btn-sm btn pointer-events-none border border-neutral/10 shadow-lg">
        <span>{"XP"}</span>
        <div className="badge badge-primary">{value}</div>
      </div>
    </div>
  );
};

const ChatCompletionCircularProgress: React.FC<{
  percentage?: number;
  className?: string;
}> = ({ percentage, className }) => {
  const value = percentage
    ? Math.floor(((percentage ?? 0) / 100) * MAX_AMOUNT_OF_XP)
    : 0;

  return (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      <div
        aria-label="progress"
        className="radial-progress whitespace-nowrap text-sm font-semibold text-primary"
        style={
          {
            "--value": percentage,
            "--size": "5rem",
            "--thickness": "0.2rem",
          } as React.CSSProperties
        }
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {value}
        {" XP"}
      </div>
    </div>
  );
};
