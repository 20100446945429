import { LogOut } from "lucide-react";

import { useAuthContext } from "~/auth/auth-store";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";

export const ButtonSignOut: React.FC = () => {
  const { signOut } = useAuthContext();
  const t = useT();

  if (!signOut) {
    return null;
  }

  return (
    <button
      title={t({ tx: "auth.signOut.signOutButton" })}
      type="button"
      className="btn-outline btn-block btn justify-start border border-neutral/10"
      onClick={signOut}
    >
      <LogOut width={16} />
      <Text tx="auth.signOut.signOutButton" />
    </button>
  );
};
