import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { resolveBreakpoint } from "~/util/breakpoints";
import { cn } from "~/util/cn";
import { useWindowDimensions } from "~/util/hooks";

export type BottomDrawerRef = {
  open: () => void;
  close: () => void;
  forceClose: () => void;
  toggle: () => void;
  isOpen: boolean;
};

type BottomDrawerResponsiveRendererContext = {
  open: () => void;
  close: () => void;
  forceClose: () => void;
  toggle: () => void;
  isOpen: boolean;
  isLarge: boolean;
};

export const BottomDrawerResponsive = forwardRef<
  BottomDrawerRef,
  {
    button?: (ctx: BottomDrawerResponsiveRendererContext) => React.ReactNode;
    children:
      | React.ReactNode
      | ((ctx: BottomDrawerResponsiveRendererContext) => React.ReactNode);
    breakpoint?: number | "sm" | "md" | "lg" | "xl" | "2xl";
    mayClose?: () => boolean;
    onClose?: () => void;
    className?: string;
  }
>(
  (
    {
      button = () => null,
      children,
      breakpoint = "sm",
      mayClose = () => true,
      onClose = () => void 0,
      className,
    },
    ref,
  ) => {
    const { width } = useWindowDimensions();
    const [isLarge, setIsLarge] = useState(
      width >= resolveBreakpoint(breakpoint),
    );
    const [isOpen, setIsOpen] = useState(false);
    const dialogRef = useRef<HTMLDialogElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!isOpen) {
        onClose();
      }
    }, [isOpen, onClose]);

    const open = useCallback(() => {
      setIsOpen(true);
      dialogRef.current?.showModal();
    }, []);

    const close = useCallback(() => {
      if (mayClose()) {
        setIsOpen(false);
        dialogRef.current?.close();
      }
    }, [mayClose]);

    const forceClose = () => {
      setIsOpen(false);
      dialogRef.current?.close();
    };

    const toggle = useCallback(() => {
      if (isOpen) {
        if (mayClose()) {
          close();
        }
      } else {
        open();
      }
    }, [isOpen, mayClose, open, close]);

    useImperativeHandle(
      ref,
      () => ({
        open,
        close,
        forceClose,
        toggle,
        isOpen,
      }),
      [isOpen, open, close, toggle],
    );

    useEffect(() => {
      setIsLarge(width >= resolveBreakpoint(breakpoint));
    }, [width, breakpoint]);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (!boxRef.current?.contains(event.target as Node) && isOpen) {
          if (mayClose()) {
            setIsOpen(false);
            dialogRef.current?.close();
          }
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, mayClose, boxRef]);

    useEffect(() => {
      const dialog = dialogRef.current;

      const handleDialogClose = (e: Event) => {
        if (isOpen && !mayClose()) {
          e.preventDefault();
        }
      };

      if (dialog) {
        dialog.addEventListener("cancel", handleDialogClose);
      }

      return () => {
        if (dialog) {
          dialog.removeEventListener("cancel", handleDialogClose);
        }
      };
    }, [isOpen, mayClose]);

    const ctx: BottomDrawerResponsiveRendererContext = {
      isOpen,
      isLarge,
      open,
      close,
      forceClose,
      toggle,
    };

    return (
      <>
        {button(ctx)}
        <dialog
          ref={dialogRef}
          className={cn(
            "modal z-50",
            isLarge ? "modal-middle" : "modal-bottom",
            className,
          )}
        >
          <div ref={boxRef} className="modal-box">
            {typeof children == "function" ? children(ctx) : children}
          </div>
        </dialog>
      </>
    );
  },
);

BottomDrawerResponsive.displayName = "BottomDrawerResponsive";
