import { HelpCircle } from "lucide-react";

import { BottomDrawerResponsive } from "~/components/bottom-drawer/responsive";
import { ChatTutorial } from "~/components/chat/chat-tutorial";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";

export const ButtonTutorial: React.FC = () => {
  const t = useT();

  return (
    <BottomDrawerResponsive
      button={({ open }) => (
        <button
          title={t({ tx: "profile.tutorial" })}
          type="button"
          onClick={open}
          className="btn-ghost btn-block btn justify-start"
        >
          <HelpCircle width={16} />
          <Text tx="profile.tutorial" />
        </button>
      )}
    >
      <ChatTutorial />
    </BottomDrawerResponsive>
  );
};
