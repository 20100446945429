import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { tx } from "shared/types/i18n";
import { z } from "zod";

import { ValidationFunction } from "../../lookup";

export const phoneValidation: ValidationFunction = (value) => {
  const result = z
    .union([z.string(), z.number()])
    .transform(String)
    .refine(
      (casted) => isPossiblePhoneNumber(casted) && isValidPhoneNumber(casted),
    )
    .safeParse(value);
  if (!result.success) {
    return { ok: false, message: { tx: tx("profile.notAValidPhoneNumber") } };
  }

  return {
    ok: true,
  };
};
