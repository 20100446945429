import { ArrowUp, Mic, Square, X } from "lucide-react";
import React, { useState } from "react";

import {
  Attachment,
  AttachmentManager,
  ButtonAttachments,
} from "./attachments";
import { FullAudioVisualizer } from "./audio/audio-control";
import {
  AudioRecordContextStatus,
  useAudioRecorder,
} from "./audio/use-audio-recorder";
import { CenteredBarsVisualizer } from "./audio/visualizers";
import { SelfAdjustingTextarea } from "./self-adjusting-textarea";
import { cn } from "~/util/cn";
import { useWindowDimensions } from "~/util/hooks";

export const SelfAdjustingTextareaWithAttachments: React.FC<{
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  onSubmit: (ctx: {
    payload: {
      text: string;
      base64Audio: string;
      attachments: Attachment[];
    };
    onDone: () => void;
  }) => void;
  autoFocus?: boolean;
}> = ({ text, setText, className, onSubmit, autoFocus }) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const { width } = useWindowDimensions();

  const recorder = useAudioRecorder();

  const handleSubmit = () => {
    const onDone = () => {
      setText("");
      recorder.cleanupResources();
      setAttachments([]);
    };

    if (recorder.status === AudioRecordContextStatus.Playback) {
      onSubmit({
        payload: {
          text: "",
          base64Audio: recorder.audioBase64,
          attachments: [],
        },
        onDone,
      });
    } else {
      onSubmit({
        payload: {
          text,
          base64Audio: "",
          attachments,
        },
        onDone,
      });
    }
  };

  return (
    <div
      className={cn(
        "rounded-2xl border border-neutral/10 bg-base-200 p-4",
        className || "mx-auto w-full max-w-screen-sm shadow-xl",
      )}
    >
      {recorder.status !== AudioRecordContextStatus.Recording ? (
        <>
          {attachments.length > 0 ? (
            <AttachmentManager
              attachments={attachments}
              setAttachments={setAttachments}
            />
          ) : null}

          {recorder.status !== AudioRecordContextStatus.Playback && (
            <SelfAdjustingTextarea
              text={text}
              setText={setText}
              className="max-h-48 w-full resize-none appearance-none break-words bg-transparent leading-relaxed text-mama-default-primary caret-mama-default-primary outline-none sm:max-h-96"
              onEnterPressed={handleSubmit}
              autoFocus={autoFocus}
            />
          )}

          <div
            className={cn(
              "gap-2",
              recorder.status === AudioRecordContextStatus.Playback
                ? "grid grid-cols-[2rem_calc(100%-4rem-0.5rem-0.5rem)_2rem]"
                : "flex items-center justify-between",
            )}
          >
            {recorder.status === AudioRecordContextStatus.Playback ? (
              <>
                <button
                  title="delete"
                  type="button"
                  className="btn-square btn-sm btn border border-neutral/20 bg-base-300 shadow-md"
                  onClick={recorder.deleteRecording}
                >
                  <X width={16} />
                </button>
                <FullAudioVisualizer
                  audioData={recorder.visualData}
                  audioSrc={recorder.audioUrl}
                  duration={recorder.duration}
                  bars={width <= 450 ? width / 20 : 45}
                />
              </>
            ) : (
              <>
                <ButtonAttachments
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
                <button
                  title="record"
                  type="button"
                  onClick={recorder.startRecording}
                  className="btn-outline btn-square btn-sm btn ml-auto border-neutral/20 bg-base-300 shadow-md"
                >
                  <Mic width={16} />
                </button>
              </>
            )}

            <button
              title="submit"
              className="btn-primary btn-square btn-sm btn"
              onClick={handleSubmit}
              disabled={
                recorder.status !== AudioRecordContextStatus.Playback &&
                text.length == 0 &&
                attachments.length == 0
              }
            >
              <ArrowUp width={16} />
            </button>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-[auto_2rem_min-content] items-center gap-2">
          <CenteredBarsVisualizer
            visualData={recorder.visualData}
            sliceLength={width < 450 ? 40 : 70}
          />

          <span className="text-xs font-medium text-primary">
            {recorder.elapsedTimeFormatted}
          </span>

          <button
            title="stop"
            type="button"
            className="btn-square btn-sm btn bg-base-300"
            onClick={recorder.stopRecording}
          >
            <Square width={16} />
          </button>
        </div>
      )}
    </div>
  );
};
