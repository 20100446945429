import { cn } from "~/util/cn";

export const CenteredBarsVisualizer: React.FC<{
  visualData: number[];
  className?: string;
  minBrightness?: number;
  maxBrightness?: number;
  sliceLength?: number;
}> = ({
  visualData,
  className = "h-8",
  minBrightness = 90,
  maxBrightness = 130,
  sliceLength = 50,
}) => {
  return (
    <div
      className={cn(
        "flex items-center justify-end space-x-1 overflow-x-hidden rounded-lg",
        className,
      )}
    >
      {visualData.slice(-sliceLength).map((level, index) => {
        const brightness =
          minBrightness + (level / 100) * (maxBrightness - minBrightness);

        return (
          <div
            key={index}
            className="min-h-1.5 w-1 flex-shrink-0 rounded-b-lg rounded-t-lg"
            style={{
              height: `${level}%`,
              backgroundColor: "var(--fallback-p,oklch(var(--p)))",
              filter: `brightness(${brightness}%)`,
            }}
          />
        );
      })}
    </div>
  );
};
