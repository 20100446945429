import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useReducer, useRef } from "react";

import { useToasts } from "~/components/app-shell/providers/toasts-provider/toasts-provider";
import { Text } from "~/components/text";

interface Window {
  webkitAudioContext?: typeof AudioContext;
}

export enum AudioRecordContextStatus {
  Init,
  Recording,
  Playback,
}

type AudioRecorderContextInit = {
  status: AudioRecordContextStatus.Init;
  startRecording: () => void;
};

type AudioRecorderContextRecording = {
  status: AudioRecordContextStatus.Recording;
  stopRecording: () => void;
  visualData: number[];
  elapsedTimeSeconds: number;
  elapsedTimeFormatted: string;
};

type AudioRecorderContextPlayback = {
  status: AudioRecordContextStatus.Playback;
  visualData: number[];
  startRecording: () => void;
  deleteRecording: () => void;
  audioUrl: string;
  audioBase64: string;
  duration: number;
  durationFormatted: string;
};

type AudioRecorderContext =
  | AudioRecorderContextInit
  | AudioRecorderContextRecording
  | AudioRecorderContextPlayback;

type RecorderState = {
  status: AudioRecordContextStatus;
  audioChunks: Blob[];
  visualData: number[];
  elapsedTimeSeconds: number;
  elapsedTimeFormatted: string;
  audioUrl: string;
  audioBase64: string;
  duration: number;
  durationFormatted: string;
};

enum RecorderActionType {
  StartRecording,
  StopRecording,
  DeleteRecording,
  AddAudioChunk,
  UpdateVisualization,
  UpdateTimer,
  ResetForNewRecording,
  Reset,
}

type RecorderAction =
  | { type: RecorderActionType.StartRecording }
  | {
      type: RecorderActionType.StopRecording;
      payload: {
        duration: number;
        durationFormatted: string;
        url: string;
        base64: string;
      };
    }
  | { type: RecorderActionType.DeleteRecording }
  | { type: RecorderActionType.AddAudioChunk; payload: Blob }
  | { type: RecorderActionType.UpdateVisualization; payload: number }
  | {
      type: RecorderActionType.UpdateTimer;
      payload: { seconds: number; formatted: string };
    }
  | { type: RecorderActionType.ResetForNewRecording }
  | { type: RecorderActionType.Reset };

const initialState: RecorderState = {
  status: AudioRecordContextStatus.Init,
  audioChunks: [],
  visualData: [],
  elapsedTimeSeconds: 0,
  elapsedTimeFormatted: "0:00",
  audioUrl: "",
  audioBase64: "",
  duration: 0,
  durationFormatted: "0:00",
};

function recorderReducer(
  state: RecorderState,
  action: RecorderAction,
): RecorderState {
  switch (action.type) {
    case RecorderActionType.StartRecording:
      return {
        ...initialState,
        status: AudioRecordContextStatus.Recording,
      };
    case RecorderActionType.StopRecording:
      return {
        ...state,
        status: AudioRecordContextStatus.Playback,
        audioUrl: action.payload.url,
        audioBase64: action.payload.base64,
        duration: action.payload.duration,
        durationFormatted: action.payload.durationFormatted,
      };
    case RecorderActionType.DeleteRecording:
      return {
        ...initialState,
      };
    case RecorderActionType.AddAudioChunk:
      return {
        ...state,
        audioChunks: [...state.audioChunks, action.payload],
      };
    case RecorderActionType.UpdateVisualization:
      return {
        ...state,
        visualData: [...state.visualData, action.payload],
      };
    case RecorderActionType.UpdateTimer:
      return {
        ...state,
        elapsedTimeSeconds: action.payload.seconds,
        elapsedTimeFormatted: action.payload.formatted,
      };
    case RecorderActionType.ResetForNewRecording:
      return {
        ...initialState,
        status: AudioRecordContextStatus.Recording,
      };
    case RecorderActionType.Reset:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const useAudioRecorder = (
  {
    timeslice = 250,
  }: {
    timeslice?: number;
  } = { timeslice: 250 },
): AudioRecorderContext & { cleanupResources: () => void } => {
  const [state, dispatch] = useReducer(recorderReducer, initialState);

  const toasts = useToasts();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioContextClosedRef = useRef<boolean>(false);
  const analyzerRef = useRef<AnalyserNode | null>(null);
  const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const animationRef = useRef<number | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number>(0);
  const audioBlobRef = useRef<Blob | null>(null);

  const safelyCloseAudioContext = useCallback(() => {
    if (audioContextRef.current && !audioContextClosedRef.current) {
      try {
        audioContextRef.current.close();
        audioContextClosedRef.current = true;
      } catch (error) {
        Sentry.captureException(error, {
          level: "error",
          extra: { label: "Error closing AudioContext" },
        });
        toasts.addToast({
          component: <Text tx="chat.errors.recordingNotPossible" />,
        });
      }
    }
  }, [toasts]);

  const cleanupResources = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      state.status === AudioRecordContextStatus.Recording
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    safelyCloseAudioContext();

    if (state.audioUrl) {
      URL.revokeObjectURL(state.audioUrl);
    }

    audioBlobRef.current = null;
  }, [state.status, state.audioUrl, safelyCloseAudioContext]);

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, [cleanupResources]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(1, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const blobToBase64 = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (!reader.result) {
          throw new Error("Reader result is not found");
        }

        resolve(reader.result.toString());
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const startRecording = async () => {
    try {
      if (state.status === AudioRecordContextStatus.Playback) {
        if (state.audioUrl) {
          URL.revokeObjectURL(state.audioUrl);
        }
        dispatch({ type: RecorderActionType.ResetForNewRecording });
      } else {
        dispatch({ type: RecorderActionType.StartRecording });
      }

      audioBlobRef.current = null;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      safelyCloseAudioContext();

      const audioContext = new (window.AudioContext ||
        (window as Window).webkitAudioContext)();
      audioContextRef.current = audioContext;
      audioContextClosedRef.current = false;

      const source = audioContext.createMediaStreamSource(stream);
      sourceRef.current = source;

      const analyzer = audioContext.createAnalyser();
      analyzerRef.current = analyzer;
      analyzer.fftSize = 256;
      analyzer.smoothingTimeConstant = 0.7;

      source.connect(analyzer);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          dispatch({
            type: RecorderActionType.AddAudioChunk,
            payload: event.data,
          });
        }
      };

      mediaRecorder.start(timeslice);

      visualize();

      startTimeRef.current = Date.now();
      timerRef.current = setInterval(() => {
        const seconds = (Date.now() - startTimeRef.current) / 1000;
        dispatch({
          type: RecorderActionType.UpdateTimer,
          payload: {
            seconds,
            formatted: formatTime(seconds),
          },
        });
      }, 1000);
    } catch (error) {
      Sentry.captureException(error, {
        level: "error",
        extra: { label: "Error while recording" },
      });
      toasts.addToast({
        component: <Text tx="chat.errors.recordingNotPossible" />,
      });
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    safelyCloseAudioContext();

    if (state.audioChunks.length > 0) {
      const blob = new Blob(state.audioChunks, { type: "audio/webm" });
      audioBlobRef.current = blob;
      const url = URL.createObjectURL(blob);

      try {
        const base64 = await blobToBase64(blob);
        const finalDuration = state.elapsedTimeSeconds;

        dispatch({
          type: RecorderActionType.StopRecording,
          payload: {
            url,
            base64,
            duration: finalDuration,
            durationFormatted: formatTime(finalDuration),
          },
        });
      } catch (error) {
        Sentry.captureException(error, {
          level: "error",
          extra: { label: "Error while retrieving base64-encoded audio" },
        });
        toasts.addToast({
          component: <Text tx="chat.errors.recordingNotPossible" />,
        });
      }
    }
  };

  const deleteRecording = () => {
    if (state.audioUrl) {
      URL.revokeObjectURL(state.audioUrl);
    }
    audioBlobRef.current = null;
    dispatch({ type: RecorderActionType.DeleteRecording });
  };

  const visualize = () => {
    if (!analyzerRef.current) return;

    const analyzer = analyzerRef.current;
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const updateVisualData = () => {
      analyzer.getByteFrequencyData(dataArray);

      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        sum += dataArray[i];
      }
      const average = sum / bufferLength;
      const volumeLevel = Math.min(100, average * 2);

      dispatch({
        type: RecorderActionType.UpdateVisualization,
        payload: volumeLevel,
      });

      animationRef.current = requestAnimationFrame(updateVisualData);
    };

    animationRef.current = requestAnimationFrame(updateVisualData);
  };

  switch (state.status) {
    case AudioRecordContextStatus.Init:
    default:
      return {
        status: AudioRecordContextStatus.Init,
        startRecording,
        cleanupResources,
      };
    case AudioRecordContextStatus.Recording:
      return {
        status: state.status,
        stopRecording,
        visualData: state.visualData,
        elapsedTimeSeconds: state.elapsedTimeSeconds,
        elapsedTimeFormatted: state.elapsedTimeFormatted,
        cleanupResources,
      };
    case AudioRecordContextStatus.Playback:
      return {
        status: state.status,
        startRecording,
        deleteRecording,
        visualData: state.visualData,
        audioUrl: state.audioUrl,
        audioBase64: state.audioBase64,
        duration: state.duration,
        durationFormatted: state.durationFormatted,
        cleanupResources,
      };
  }
};
