import { Pause, Play } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

import { cn } from "~/util/cn";

export const FullAudioVisualizer: React.FC<{
  audioData: number[];
  audioSrc: string;
  className?: string;
  minBrightness?: number;
  maxBrightness?: number;
  trackOpacity?: number;
  duration: number;
  bars?: number;
}> = ({
  audioData,
  audioSrc,
  className,
  minBrightness = 90,
  maxBrightness = 130,
  trackOpacity = 0.6,
  duration,
  bars = 50,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  const averagedData = React.useMemo(() => {
    if (!audioData.length) return [];

    const chunkSize = Math.floor(audioData.length / bars);
    const result = [];

    for (let i = 0; i < bars; i++) {
      const start = i * chunkSize;
      const end = i === bars - 1 ? audioData.length : start + chunkSize;
      const chunk = audioData.slice(start, end);
      const average = chunk.reduce((sum, val) => sum + val, 0) / chunk.length;
      result.push(average);
    }

    return result;
  }, [audioData, bars]);

  const currentPosition = Math.floor((currentTime / duration) * bars);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);

    audio.load();

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
    };
  }, []);

  const togglePlay = () => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  const seekAudio = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current || !duration) return;

    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;

    audioRef.current.currentTime = Math.round(percentage * duration);
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div
      className={cn(
        "grid grid-cols-[2rem_auto_4rem] items-center gap-2",
        className,
      )}
    >
      <audio ref={audioRef} src={audioSrc} preload="metadata" />

      <button
        onClick={togglePlay}
        className="btn-square btn-sm btn ml-auto border border-neutral/20 bg-base-300 shadow-md"
      >
        {isPlaying ? <Pause width={16} /> : <Play width={16} />}
      </button>

      <div
        className="h-8 w-full cursor-pointer overflow-hidden rounded-lg"
        onClick={seekAudio}
      >
        <div className="flex h-full items-center gap-0.5 sm:gap-1">
          {averagedData.map((level, index) => {
            const brightness =
              minBrightness + (level / 100) * (maxBrightness - minBrightness);
            const active = index <= currentPosition;

            return (
              <div
                key={index}
                className="min-h-1 rounded-b-md rounded-t-md transition-all duration-300 ease-in-out"
                style={{
                  height: `${level}%`,
                  width: `${100 / bars}%`,
                  backgroundColor: active
                    ? "var(--fallback-p,oklch(var(--p)))"
                    : `var(--fallback-n,oklch(var(--n) / ${trackOpacity}))`,
                  filter: `brightness(${brightness}%)`,
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="flex items-center justify-center gap-[1px] text-xs font-medium text-primary">
        <span>{formatTime(currentTime)}</span>
        <span>{"/"}</span>
        <span>{formatTime(duration)}</span>
      </div>
    </div>
  );
};
