import {
  BookOpen,
  ChartPie,
  ClipboardList,
  Lock,
  MessageCircle,
} from "lucide-react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { EnabledPage } from "shared/model/pages";

import { useIsPageLocked } from "~/components/lock";
import { Text } from "~/components/text";
import { cn } from "~/util/cn";

type MenuBarPosition = "start" | "end";

export interface ChildNavbarRoute {
  page?: EnabledPage;
  path: string;
  title: string;
  isActive: boolean;
  position: MenuBarPosition;
  isLocked?: boolean;
}

export interface ParentNavbarRoute {
  childRoutes: ChildNavbarRoute[];
  title: string;
  uniqueKey: string;
  position: MenuBarPosition;
}

export type NavbarRoute = ChildNavbarRoute | ParentNavbarRoute;

export const NavLinks: React.FC<{
  routes: NavbarRoute[];
}> = ({ routes }) => {
  return (
    <>
      {routes.map((route, index) => (
        <li key={`navbar-routes-${route.title}-${index}`}>
          {isParentRoute(route) ? (
            <ParentNavLink {...route} />
          ) : (
            <ChildNavLink {...route} />
          )}
        </li>
      ))}
    </>
  );
};

const routesIconsMap = {
  "/chat": <BookOpen width={16} />,
  "/mama-answer": <MessageCircle width={16} />,
  "/report": <ClipboardList width={16} />,
} as const;

const ChildNavLink: React.FC<ChildNavbarRoute> = ({
  page,
  path,
  title,
  isActive,
}) => {
  const { isLocked } = useIsPageLocked(page);

  const Icon =
    routesIconsMap[page?.route as keyof typeof routesIconsMap] ||
    (page?.route.endsWith("-analysis") && <ChartPie width={16} />) ||
    null;

  return (
    <NavLink
      to={path}
      className={cn(
        "btn-block btn justify-start",
        isLocked && "cursor-not-allowed",
        isActive ? "btn-primary" : "btn-ghost",
      )}
    >
      {Icon}

      <Text text={title} />

      {isLocked && <Lock width={16} />}
    </NavLink>
  );
};

const ParentNavLink: React.FC<ParentNavbarRoute> = ({ title, childRoutes }) => {
  const [isOpen, setIsOpen] = useState(
    childRoutes.findIndex((route) => route.isActive) > 0,
  );

  return childRoutes.length === 0 ? null : childRoutes.length === 1 ? (
    <ChildNavLink {...childRoutes[0]} title={title} />
  ) : childRoutes.length > 1 ? (
    <div
      className={cn(
        "collapse-arrow collapse cursor-pointer",
        isOpen ? "collapse-open" : "collapse-close",
      )}
    >
      <input
        title={title}
        type="checkbox"
        className="cursor-pointer"
        onClick={() => setIsOpen((prev) => !prev)}
      />
      <div className="btn-ghost collapse-title btn-block btn justify-start">
        <Text as="span" text={title} />
      </div>
      <ul className="collapse-content flex flex-col gap-1.5">
        {childRoutes.map((route) => (
          <li key={route.path}>
            <ChildNavLink key={route.path} {...route} />
          </li>
        ))}
      </ul>
    </div>
  ) : childRoutes.length === 1 ? (
    <ChildNavLink {...childRoutes[0]} title={title} />
  ) : null;
};

const isParentRoute = (route: NavbarRoute): route is ParentNavbarRoute =>
  !("path" in route);

export const useSplitNavbarRoutes = (
  routes: NavbarRoute[] = [],
): Record<MenuBarPosition, NavbarRoute[]> => {
  return {
    start: routes.filter((route) => route.position === "start"),
    end: routes.filter((route) => route.position === "end"),
  };
};
