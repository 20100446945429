import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PlainI18nProps } from "shared/types/i18n";
import { z } from "zod";

export type MamaNavigateState = {
  redirectUrl?: string;
  prefillValues?: Record<string, string>;
  info?: PlainI18nProps;
  error?: PlainI18nProps;
};

type MamaNavigateErrorState = MamaNavigateState & { error: PlainI18nProps };
type MamaNavigateInfoState = MamaNavigateState & { info: PlainI18nProps };

export const useMamaNavigateState: () => MamaNavigateState = () => {
  const { state } = useLocation();

  const authNavigateState = useMemo(() => {
    const emptyState: MamaNavigateState = {};

    if (isMamaNavigateErrorState(state)) {
      emptyState.error = {
        text: state.error.text,
        tx: state.error.tx,
        txUnchecked: state.error.txUnchecked,
        txData: state.error.txData,
      };
    } else if (isMamaNavigateInfoState(state)) {
      emptyState.info = {
        text: state.info.text,
        tx: state.info.tx,
        txUnchecked: state.info.txUnchecked,
        txData: state.info.txData,
      };
    }

    const result = z
      .object({
        prefillValues: z.object({}),
      })
      .safeParse(state);
    if (result.success) {
      emptyState.prefillValues = state.prefillValues;
    }

    return emptyState;
  }, [state]);

  return authNavigateState;
};

const schemaErrorState = z.object({
  error: z.object({}),
});

export const isMamaNavigateErrorState = (
  state: unknown,
): state is MamaNavigateErrorState => {
  return schemaErrorState.safeParse(state).success;
};

const schemaInfoState = z.object({
  info: z.object({}),
});

export const isMamaNavigateInfoState = (
  state: unknown,
): state is MamaNavigateInfoState => {
  return schemaInfoState.safeParse(state).success;
};
