import { z } from "zod";

import { isOpadeIdRegex } from "../../../../../../util/regex";
import { ValidationFunction } from "../../lookup";

export const opadeIdValidation: ValidationFunction = (value) => {
  const result = z
    .union([z.string(), z.number()])
    .transform(String)
    .refine((casted) => isOpadeIdRegex.test(casted))
    .safeParse(value);
  if (!result.success) {
    return {
      ok: false,
      message: {
        tx: "signUp.customFields.opadePatientId.errorOpadeIdFormat",
      },
    };
  }

  return {
    ok: true,
  };
};
