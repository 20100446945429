import { useCallback, useEffect, useRef, useState } from "react";

import { Profile } from "./profile";
import { useModal } from "~/models/modal-provider";

export const useProfile: () => {
  hideProfile: () => void;
  showProfile: () => void;
} = () => {
  const { showModal: showProfileModal, hideModal: hideProfileModal } =
    useModal();
  const { showModal: showWarningModal, hideModal: hideWarningModal } =
    useModal();
  const [hasChanges, setHasChanges] = useState(false);
  const hasChangesRef = useRef(hasChanges);

  const [shouldCloseProfile, setShouldCloseProfile] = useState(false);

  useEffect(() => {
    hasChangesRef.current = hasChanges;
  }, [hasChanges]);

  useEffect(() => {
    if (shouldCloseProfile) {
      hideProfileModal();
      hideWarningModal();
      setShouldCloseProfile(false);
    }
  }, [shouldCloseProfile, hideProfileModal, hideWarningModal]);

  const showWarning = useCallback(() => {
    showWarningModal({
      title: { tx: "profile.unsavedChangesTitle" },
      description: { tx: "profile.unsavedChanges" },
      onConfirm: () => {
        setShouldCloseProfile(true);
      },
      confirmButtonTx: "general.yes",
      closeButtonTx: "general.no",
    });
  }, [showWarningModal]);

  const ensureCanClose = useCallback(() => {
    if (hasChangesRef.current) {
      showWarning();
      return false;
    }
    return true;
  }, [showWarning]);

  const hideProfile = useCallback(() => {
    if (ensureCanClose()) {
      hideProfileModal();
    }
  }, [ensureCanClose, hideProfileModal]);

  const showProfile = useCallback(() => {
    showProfileModal({
      showsWindowCloseButton: true,
      closeOnClickOutside: true,
      showCloseButton: false,
      title: { tx: "profile.headerTitle" },
      ensureCanClose,
      children: (
        <div>
          <Profile
            onSavedProfile={hideProfileModal}
            setHasChanges={setHasChanges}
            onCloseButton={hideProfileModal}
          />
        </div>
      ),
    });
  }, [hideProfileModal, showProfileModal, ensureCanClose]);

  return { showProfile, hideProfile };
};
