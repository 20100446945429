import { z } from "zod";

import { ValidationFunction } from "../../lookup";

export const createValidationFunctionForMinLength = (
  minLength: number,
): ValidationFunction => {
  const schema = z
    .union([z.string(), z.number()])
    .transform(String)
    .refine((value) => value.length >= minLength);

  return (value) => {
    const result = schema.safeParse(value);
    if (!result.success) {
      return {
        ok: false,
        message: {
          tx: "userData.WhereDidYouHearAboutMama.answerNeedsToBeLonger",
          txData: {
            characters: minLength,
          },
        },
      };
    }

    return {
      ok: true,
    };
  };
};
