import { z } from "zod";

import { MamaCognitoError } from "./cognito-errors";

const accessTokenPayloadSchema = z.object({
  sub: z.string(),
  username: z.string(),
  exp: z.number(),
  iat: z.number(),
});
type accesTokenPayload = z.infer<typeof accessTokenPayloadSchema>;

export const getTimeToTokenRefresh = (token: string): number => {
  const jwtPayload = getDecodedPayloadFromAccessToken(token);
  const refreshInterval = calculateTimeToTokenRefresh(jwtPayload);
  return refreshInterval;
};

const calculateTimeToTokenRefresh = (payload: accesTokenPayload): number => {
  const refreshTokenAtPercentageOfTotalValidity = 0.8;
  const nowTimestampInMilliseconds = Date.now();
  const refreshAtInMilliseconds =
    Math.floor(
      payload.iat +
        (payload.exp - payload.iat) * refreshTokenAtPercentageOfTotalValidity,
    ) * 1000;
  const timeToRefreshInMilliseconds =
    refreshAtInMilliseconds - nowTimestampInMilliseconds;

  return timeToRefreshInMilliseconds;
};

export const getDecodedPayloadFromAccessToken = (
  token: string,
): accesTokenPayload => {
  try {
    const payload = token.split(".")[1];
    const base64DecodedPayload = JSON.parse(atob(payload));

    const result = accessTokenPayloadSchema.safeParse(base64DecodedPayload);
    if (!result.success) {
      throw new MamaCognitoError({
        name: "auth.signIn.errors.errorAssertingAccessTokenPayloadType",
      });
    }

    return result.data;
  } catch (err) {
    throw new MamaCognitoError({
      name: "auth.signIn.errors.errorDecodingMagicLinkToken",
    });
  }
};
