import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import { DataCollectionImplementation } from "../types";
import { FormLanguageDropDown } from "~/components/form/language/form-language-dropdown-input";

export const languageInput: DataCollectionImplementation = {
  wasCompleted: (user) => !!user.userAttributes?.language,
  Component: ({ errors, control, register, user }) => {
    const preSelectedLanguage = user.userAttributes?.language;
    const {
      i18n: { language: currentlySelectedLanguage },
    } = useTranslation();
    const language: Language | undefined = (preSelectedLanguage ??
      currentlySelectedLanguage) as Language | undefined;

    useEffect(() => {
      register("language", { value: language });
    }, [language, register]);

    return (
      <FormLanguageDropDown
        name="language"
        control={control}
        labelClassName="font-bold"
        selectedValueClassName="font-normal"
        className="input-bordered w-full rounded-lg border"
        title={{ tx: "editNews.language.label" }}
        error={{ txUnchecked: errors.language?.message }}
      />
    );
  },
};
