import { Language } from "shared/model/languages";

import { IconType } from "~/components/icons/svg-icon";

export type Jwt = string;
export enum JwtState {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  MISSING = "MISSING",
}
export type LoginState =
  | {
      jwt: Jwt;
      state: JwtState.SUCCESS;
    }
  | {
      state: JwtState.PENDING;
    }
  | {
      state: JwtState.MISSING;
    };
export type RefetchJwtFn = () => Jwt;

export type AuthContextType = {
  jwt: Jwt;
  ensureValidJwt: () => Promise<Jwt | undefined>;
  signOut: () => void;
};

export type HubPayloadType = {
  event: string;
  data?: unknown;
  message?: string;
};

export enum SniperLinkEnum {
  GMAIL = "GMAIL",
  OUTLOOK = "OUTLOOK",
  YAHOO = "YAHOO",
  GMX = "GMX",
  LIBERO = "LIBERO",
}

export type SniperLinkConfig = {
  [key in SniperLinkEnum]: SniperLink;
};

type SniperLink = {
  link: string;
  logo: IconType;
  language?: Language;
};
