import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { RefreshCw, Save, X } from "lucide-react";
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import React from "react";
import { useForm } from "react-hook-form";

import { ManageAccountDrawer } from "./manage-account-drawer";
import { UpdateUserAttributesDto } from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../api/use-api";
import { Form } from "../components/form/form";
import { LoadingIndicator } from "../components/loading-spinner";
import { useTenantConfig } from "../tenant-settings";
import { ME_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";
import { Loading } from "~/components/loading";
import { Text } from "~/components/text";
import { DataCollectionImplementationsRecord } from "~/data-collection/constants";
import { useT } from "~/i18n/use-t";

export type ProfileRef = {
  reset: () => void;
};

type ProfileProps = {
  onSavedProfile: () => void;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  onCloseButton: () => void;
};

export const Profile = forwardRef<ProfileRef, ProfileProps>(
  ({ onSavedProfile, setHasChanges, onCloseButton }, ref) => {
    const queryClient = useQueryClient();
    const tenantId = useTenantId();
    const tenantConfig = useTenantConfig();
    const user = useUser();
    const t = useT();

    const {
      control,
      register,
      formState: { errors, isSubmitting, isDirty: hasChanges },
      handleSubmit,
      reset,
      watch,
    } = useForm<UpdateUserAttributesDto>({
      defaultValues: user?.userAttributes,
    });

    useImperativeHandle(
      ref,
      () => ({
        reset,
      }),
      [reset],
    );

    useEffect(() => {
      setHasChanges(hasChanges);
    }, [setHasChanges, hasChanges]);

    const inputs = useMemo(
      () =>
        tenantConfig.profileInputs.map((input) => {
          return DataCollectionImplementationsRecord[input];
        }),
      [tenantConfig.profileInputs],
    );

    const { mutate: updateUserAttributes } = useApiMutation(
      "backend",
      (api) => (updateUserAttributesDto: UpdateUserAttributesDto) =>
        api.updateUserAttributes({
          updateUserAttributesDto,
          ...toTenantIdHeader(tenantId),
        }),
      undefined,
      { trackErrors: true },
      {
        onSuccess(updatedData) {
          reset(updatedData);
          setHasChanges(false);
          queryClient.invalidateQueries(ME_KEY(tenantId));
          onSavedProfile();
        },
      },
    );

    const updateProfileHandler = handleSubmit(
      async (data: UpdateUserAttributesDto) => {
        setHasChanges(false);
        updateUserAttributes(data);
      },
    );

    const handleReset = () => {
      reset(user?.userAttributes);
      setHasChanges(false);
    };

    const watchedLanguage = watch("language");

    useEffect(() => {
      if (watchedLanguage) {
        i18next.changeLanguage(watchedLanguage);
      }
    }, [watchedLanguage]);

    return user ? (
      <div className="flex flex-col gap-4">
        <div className="flex w-full items-center justify-end gap-2">
          <Text
            as="h2"
            tx="profile.headerTitle"
            className="mr-auto text-lg font-bold text-mama-default-primary"
          />

          <ManageAccountDrawer user={user} />
          <button
            title="reset"
            type="button"
            className="btn-outline btn-block btn-square btn-sm btn border-neutral/20"
            onClick={handleReset}
            disabled={!hasChanges || isSubmitting}
          >
            <RefreshCw width={16} />
          </button>
          <button
            title="close"
            type="button"
            className="btn-outline btn-block btn-square btn-sm btn border-neutral/20"
            onClick={onCloseButton}
          >
            <X width={16} />
          </button>
        </div>
        <Form
          onSubmit={updateProfileHandler}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
              updateProfileHandler();
            }
          }}
        >
          <div className="mb-6 flex flex-col gap-4">
            {inputs.map((input, index) => (
              <input.Component
                key={index}
                user={user}
                register={register}
                control={control}
                errors={errors}
              />
            ))}
          </div>

          <button
            title={t({ tx: "general.confirm" })}
            type="submit"
            className="btn-primary btn-block btn"
            disabled={!hasChanges || isSubmitting}
          >
            <Loading loading={isSubmitting} otherwise={<Save width={16} />} />
            <Text tx="general.confirm" />
          </button>
        </Form>
      </div>
    ) : (
      <LoadingIndicator as="spinner" className="justify-center" />
    );
  },
);
Profile.displayName = "Profile";
